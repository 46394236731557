<template>
  <div class="box-graphics">
    <div class="d-flex justify-end">
      <configuration class="" @reloadBudgetCharts="reloadCharts"></configuration>
      <seguimiento class="ml-5" @reloadBudgetCharts="reloadCharts"></seguimiento>
    </div>
    <h3 class="text-center tway-header-1">Presupuesto</h3>
    <div class="resumen">
      <div class="resumen-title tway-violet--text">Resumen</div>
      <div class="resumen-content">
        <div class="resumen-detail">
          <div class="resumen-detail-item" v-for="(resume, index) in summaryBudget.resume" :key="index">
            <div>{{ resume.description }}:</div>
            <div class="resumen-detail-item-amount">
              <div>{{ monetaryUnit + resume.value }}</div>
              <div>{{ resume.percentage }}</div>
            </div>
          </div>
        </div>
        <div class="resumen-bar">
          <VueApexCharts
            ref="summaryBudget"
            :series="summaryBudget.series"
            :options="summaryBudget.options"
            type="bar"
            height="150"
            class="full"
          />
        </div>
      </div>
    </div>
    <!-- SEGUIMIENTO GASTO/PRESUPUESTO -->
    <div class="graphics-container">
      <div class="tway-violet--text">
        <p>
          Seguimiento por Gasto/Presupuesto
          <ToolTips description="Ratio del gasto real sobre el Presupuestado por mes." />
        </p>
      </div>
      <div>
        <VueApexCharts
          ref="followUPPerPeriod"
          :series="followUPPerPeriod.series"
          :options="followUPPerPeriod.options"
          height="300"
          type="line"
          class="full"
        />
      </div>
    </div>
    <!-- CENTRO DE COSTO -->
    <div class="graphics-container-duo">
      <div class="tway-violet--text">
        {{ titleCostCenter }}
      </div>
      <div class="mt-2">
        <div>
          <div>
            <div>
              <div class="tway-violet--text mt-2 ml-2">
                <p>
                  Agregado
                  <ToolTips description="Gasto sobre Presupuesto por Unidad de Costo." />
                </p>
              </div>
              <VueApexCharts
                ref="followUPByCostCenter"
                :series="followUPByCostCenter.series"
                :options="followUPByCostCenter.options"
                type="bar"
                class="full"
                height="300"
                width="500"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="tway-violet--text">
            <p>
              Periodo
              <ToolTips description="Distribución del Gasto por mes." />
            </p>
          </div>
          <VueApexCharts
            ref="costCenter"
            :series="costCenter.series"
            :options="costCenter.options"
            type="bar"
            height="300"
            width="500"
            class="full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Configuration from "../../components/pmo/presupuesto/configuration.vue";
import Seguimiento from "../../components/pmo/presupuesto/seguimiento.vue";
import BudgetService from "@/services/pmo/budget/budgetService";
import VueApexCharts from "vue-apexcharts";
import ToolTips from "../../components/pmo/utils/ToolTips";

export default {
  name: "Budget",
  components: {
    Configuration,
    Seguimiento,
    VueApexCharts,
    ToolTips,
  },
  data() {
    return {
      charts: {
        timeLine: {
          serie: null,
        },
        stacked: {
          serie: [],
        },
      },
      serieStacked: [],
      summaryBudget: {
        resume: [],
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 150,
            width: 1250,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 0,
            },
          },
          stroke: {
            width: 0,
            colors: ["#fff"],
            curve: "smooth",
            lineCap: "round",
            dashArray: 0,
          },
          title: {
            text: undefined,
          },
          xaxis: {
            categories: [""],
          },
          yaxis: {
            title: {
              text: undefined,
            },
          },
          colors: ["#16C62E", "#9f9f9f", "#7319D5", "#D7263D", "#4A148C"],
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "left",
            horizontalAlign: "left",
            offsetX: 0,
            offsetY: 0,
          },
          dataLabels: {
            style: { colors: ["#232423", "white", "white", "white", "white"] },
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      followUPPerPeriod: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            height: 550,
            type: "line",
            stacked: false,
            toolbar: {
              show: true,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
              },
              autoSelected: "zoom",
            },
          },
          colors: ["#16C62E", "#AEEA00", "#4A148C", "#7319D5"],
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "round",
            width: 2,
            dashArray: 0,
          },
          plotOptions: {
            bar: {
              columnWidth: "60%",
              roderRadius: 10,
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [2],
            formatter: function (val) {
              return val + "%";
            },
          },
          xaxis: {
            categories: [],
          },
          yaxis: [
            {
              title: {
                text: "",
              },
            },
            {
              opposite: true,
              title: {
                text: "Gasto/Presupuesto %",
              },
            },
          ],
          tooltip: {
            shared: false,
            intersect: true,
            x: {
              show: false,
            },
          },
          legend: {
            horizontalAlign: "center",
            position: "right",
          },
        },
      },
      followUPByCostCenter: {
        series: [],
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 430,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 5,
              dataLabels: {
                position: "top",
              },
            },
          },
          colors: ["#16C62E", "#7319D5"],
          dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
              fontSize: "12px",
              colors: ["#232423", "#fff"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            categories: [],
          },
        },
      },
      costCenter: {
        options: {
          chart: {
            fontFamily: "Josefin Sans, Arial, sans-serif",
            type: "bar",
            height: 650,
            width: 1200,
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 5,
            },
          },
          // Violeta Claro:'#7319D5' - Violeta Oscuro: '#4A148C' - Verde Oscuro: '#AEEA00' - Verde Claro:'#16C62E' - Gris: '#7F7F7F'
          colors: ["#7F7F7F", "#4A148C", "#16C62E", "#7319D5", "#AEEA00"],
          stroke: {
            show: true,
            curve: "smooth",
            lineCap: "round",
            width: 2,
            dashArray: 0,
          },
          title: {
            text: undefined,
          },
          xaxis: {
            categories: [],
            labels: {
              formatter: function (val) {
                return val;
              },
            },
          },
          yaxis: {
            title: {
              text: "",
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          fill: {
            opacity: 1,
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
            offsetX: 0,
          },
          dataLabels: {
            style: {
              colors: ["white", "white", "#232423", "white", "#232423"],
            },
          },
        },
        series: [],
      },
      titleCostCenter: "",
      monetaryUnit: "",
    };
  },
  methods: {
    reloadCharts() {
      this.callService();
      this.getMonetaryUnit();
    },
    async callService() {
      await BudgetService.getSummaryBudget(this.project)
        .then((data) => {
          this.summaryBudget.series = data.series;
          this.summaryBudget.resume = data.resume;
        })
        .catch((err) => console.dir(err));

      await BudgetService.getPeriodTrack(this.project)
        .then((data) => {
          this.followUPPerPeriod.series = data;
          this.$refs.followUPPerPeriod.updateOptions({
            xaxis: {
              categories: data[0].dates,
            },
            yaxis: [
              {
                title: {
                  text: this.monetaryUnit,
                },
              },
              {
                opposite: true,
                title: {
                  text: "Gasto/Presupuesto %",
                },
              },
            ],
          });
        })
        .catch((err) => console.dir(err));

      await BudgetService.getTrackByCostCenter(this.project)
        .then((data) => {
          this.followUPByCostCenter.series = data;
          this.$refs.followUPByCostCenter.updateOptions({
            xaxis: {
              categories: data[0].categories,
            },
          });
        })
        .catch((err) => console.dir(err));

      await BudgetService.getCostCenter(this.project)
        .then((data) => {
          this.costCenter.series = data.chart;
          this.titleCostCenter = data.title;
          this.$refs.costCenter.updateOptions({
            xaxis: {
              categories: data.chart[0].categories,
            },
          });
          this.$refs.costCenter.updateOptions({
            yaxis: {
              title: { text: this.monetaryUnit },
            },
          });
        })
        .catch((err) => console.dir(err));
    },
    getMonetaryUnit() {
      BudgetService.getTableCostCenter(this.project)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => console.dir(err));
    },
  },
  async mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;

    this.callService();
    this.getMonetaryUnit();
  },
};
</script>

<style lang="scss" scoped>
.box-graphics {
  max-width: 1400px;
  min-width: 1100px;
}

.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.resumen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 235px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  justify-content: flex-start;
  margin: 25px 0px 15px 0px;
  font-size: 15px;
  padding: 30px 20px 0px 20px;
  border-radius: 20px;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: none !important;
}

.resumen > .resumen-content > .resumen-detail {
  width: 23%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: 13px !important;
}

.resumen > .resumen-content > .resumen-detail > .resumen-detail-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.resumen > .resumen-content > .resumen-detail > .resumen-detail-item > .resumen-detail-item-amount {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.resumen-detail-item-amount > div:first-child {
  text-align: right;
  width: 65%;
}

.resumen > .resumen-content > .resumen-bar {
  display: flex;
  width: 80%;
  height: auto;
}

.resumen-title {
  display: block;
  flex-direction: column;
  float: left;
  justify-content: space-between;
  font-size: 18px;
}

.resumen-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: solid black 1px;
  justify-content: space-between;
  margin-bottom: 15px;
}

.full {
  width: 100%;
  height: fit-content;
}

.graphics-container {
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  width: 100%;
  padding: 30px 20px 25px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: column;
  }
}

.graphics-container-duo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  margin: 25px 0px 25px 0px;
  div {
    display: flex;
    flex-direction: row;
  }
}

.graphics-container-duo {
  div {
    div {
      display: flex;
      flex-direction: column;
      width: 47%;

      div {
        width: 100%;
      }

      //border: 1px solid black;
    }
  }
}
</style>
