<template>
  <div class="text-right">
    <div class="d-flex justify-end">
      <seguimiento class="ml-5" @reloadBudgetData="reloadData()"></seguimiento>
    </div>
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="tway-violet--text accent-4"> mdi-chart-line </v-icon>
          Seguimiento
        </v-btn>
      </template>

      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="dialog = false" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Seguimiento de Presupuesto</h3>
            <h4 class="text-center font-weight-regular">Ingresa nuevo Seguimiento</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="20%" scope="col">Año</th>
                    <th class="text-center" width="20%" scope="col">Mes</th>
                    <th class="text-center" width="20%" scope="col">Centro Costo</th>
                    <th class="text-center" width="20%" scope="col">Ítem</th>
                    <th class="text-center" width="20%" scope="col">Monto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :v-if="datos.length > 0" v-for="item in datos" :key="item.index">
                    <td class="text-center">
                      <v-select
                        :items="combos.years"
                        item-value="id"
                        item-text="name"
                        v-model="item.anio"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="combos.months"
                        item-value="id"
                        item-text="name"
                        v-model="item.mes"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="costos"
                        item-value="id"
                        item-text="name"
                        v-model="item.costo"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="item.item"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                        blur
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <span class="peso mt-3">{{ monetaryUnit }}</span>
                      <v-text-field v-model="item.monto" type="number" min="0" outlined></v-text-field>
                    </td>
                    <td class="text-center">
                      <div md="12" class="text-center d-flex justify-center mx-lg-auto mb-6">
                        <v-col md="12" class="text-center d-flex justify-center mx-lg-auto">
                          <v-btn
                            class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                            outlined
                            color="indigo lighten-2"
                            @click="addExpense()"
                          >
                            <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                            Agregar
                          </v-btn>
                        </v-col>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-divider class="my-6"></v-divider>
            <h4 class="text-center mt-10 font-weight-regular">Listado de Seguimiento Presupuesto</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th width="5%" scope="col">ID</th>
                    <th width="15%" scope="col">Año</th>
                    <th width="15%" scope="col">Mes</th>
                    <th width="20%" scope="col">Centro Costo</th>
                    <th width="15%" scope="col">Ítem</th>
                    <th width="15%" scope="col">Monto</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="items.length > 0"
                    v-for="expense in expenses"
                    :key="expense.index"
                    :class="expense.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td class="text-center">{{ expense.index }}</td>
                    <td class="text-center">
                      <v-select
                        :items="items.anio"
                        item-value="id"
                        item-text="name"
                        v-model="expense.yearId"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="combos.months"
                        item-value="id"
                        item-text="name"
                        v-model="expense.monthId"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="costos"
                        item-value="id"
                        item-text="name"
                        v-model="expense.idCostCenter"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-text-field
                        v-model="expense.itemExpense"
                        item-value="id"
                        item-text="name"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center">
                      <span class="peso">{{ monetaryUnit }}</span>
                      <v-text-field
                        :item="expenses"
                        v-model="expense.budgetExpense"
                        type="number"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(expense)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="primary"
                            class="save"
                            @click="updateExpense(expense)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="deleteExpense(expense.index)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row>
              <v-col md="12" class="justify-center">
                <div class="btn d-flex justify-center mt-6">
                  <v-btn
                    outlined
                    color="indigo lighten-2"
                    class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                    @click="closeConfigurationDialog()"
                  >
                    <v-icon class="tway-violet--text">save</v-icon>
                    Guardar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BudgetService from "@/services/pmo/budget/budgetService";

export default {
  data() {
    return {
      dialog: false,
      anios: [],
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      costos: [],
      datos: [
        {
          anio: "",
          mes: "",
          costo: "",
          item: "",
          monto: 0,
          index: 0,
        },
      ],
      nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 15 || "Máximo 15 carácteres."],
      isVisible: true,
      activeClass: "is-visible",
      combos: {
        years: [],
        months: [],
        yearIds: [],
      },
      expenses: [],
      maxIndex: 0,
      monetaryUnit: "",
    };
  },
  methods: {
    reloadData() {
      this.getExpenses();
      this.getMonetaryUnit();
    },
    closeConfigurationDialog() {
      this.dialog = false;
      this.$emit("reloadBudgetCharts");
    },
    clearForm() {
      this.datos[0].anio = "";
      this.datos[0].mes = "";
      this.datos[0].costo = "";
      this.datos[0].item = "";
      this.datos[0].monto = 0;
    },
    AddItem() {
      this.items.push({
        anio: this.combos.years,
        mes: this.combos.months,
        costo: this.costo,
        total: this.total,
        monto: this.monto,
        index: this.maxIndex + 1,
      });
    },
    removeItem() {
      if (this.items.length > 1) {
        this.items.splice(this.items, 1);
      }
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    formatDate(fecha) {
      fecha.getYear();
    },
    addExpense() {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        itemExpense: this.datos[0].item,
        yearId: this.datos[0].anio,
        monthId: this.datos[0].mes,
        budgetExpense: this.datos[0].monto,
        idCostCenter: this.datos[0].costo,
        index: this.maxIndex + 1,
      };
      BudgetService.addExpense(data)
        .then(() => {
          this.item = [];
          this.getExpenses();
          this.clearForm();
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.clearForm();
        });
    },
    updateExpense(expense) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        itemExpense: expense.itemExpense,
        yearId: expense.yearId,
        monthId: expense.monthId,
        budgetExpense: expense.budgetExpense,
        idCostCenter: expense.idCostCenter,
        index: expense.index,
      };

      BudgetService.addExpense(data)
        .then(() => {
          this.item = [];
          this.getExpenses();
          this.clearForm();
        })
        .catch((err) => {
          console.dir(err);
        })
        .finally(() => {
          this.clearForm();
        });
    },

    deleteExpense(index) {
      const data = {
        projectId: this.projectIds.projectId,
        enterpriseId: this.projectIds.enterpriseId,
        index: index,
      };

      BudgetService.deleteExpense(data)
        .then(() => {
          this.getExpenses();
        })
        .catch((error) => console.dir(error));
    },

    getExpenses() {
      BudgetService.getExpenses(this.projectIds)
        .then((data) => {
          this.expenses = data;
        })
        .catch((err) => {
          console.dir(err);
        });
      BudgetService.getCostCenter(this.projectIds).then((data) => {
        this.costos = data.chart;
      });
      BudgetService.getIndexMaxBudgetExpenses(this.projectIds)
        .then((data) => {
          this.maxIndex = data;
        })
        .catch((err) => console.dir(err));

      BudgetService.getAllBudgetByIdProjectAndIdEnterprise(this.projectIds)
        .then((data) => {
          this.items = data.items;
          this.items.anio = data.general.years;
          this.combos.years = data.general.years;
          this.combos.months = data.general.months;
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    getMonetaryUnit() {
      BudgetService.getTableCostCenter(this.projectIds)
        .then((data) => {
          this.monetaryUnit = data.monetaryUnit;
        })
        .catch((err) => console.dir(err));
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.projectIds = pmoProjectIds;

    this.getExpenses();
    this.getMonetaryUnit(this.projectIds);
  },
};
</script>

<style>
.shrink {
  width: 150px;
}

.bar {
  width: 350px;
}

.modalSystem {
  border-radius: 0px !important;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}

.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}

.modalSystem label.v-label.theme--light,
.edit_row .v-text-field__details {
  font-family: "Josefin Sans", sans-serif !important;
}

.modalSystem fieldset {
  border-color: #4a148c !important;
}

.modalSystem label.v-label--active {
  color: #4a148c !important;
}

.modalSystem button.v-btn.primary {
  background-color: #4a148c !important;
  border-color: #4a148c !important;
}

.edit_row .v-input__slot,
.edit_row .v-select {
  min-height: 35px !important;
  max-height: 35px !important;
  margin-bottom: 0px !important;
}

.edit_row .v-text-field--outlined .v-label {
  top: 8px;
  font-size: 13px !important;
}

.edit_row .v-input__append-inner {
  margin-top: 5px !important;
}

.edit_row .v-text-field__details {
  display: none;
}

.edit_row .v-select__selection,
.edit_row .v-text-field__slot input {
  font-size: 0.875rem;
}

.edit_row label.v-label.v-label--active.theme--light {
  top: 18px;
}

tr.edit_row td {
  height: 60px !important;
}

.modalSystem .edit_row fieldset {
  border-color: #fff !important;
  background-color: #f2f2f2;
}

.modalSystem .v-data-table__wrapper {
  max-height: 408px;
  overflow: auto;
}

.modalSystem .edit_row.is-visible fieldset {
  border-color: transparent !important;
  background-color: transparent;
}

.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}

.peso {
  float: left;
  margin-right: 5px;
  top: 7px;
  position: relative;
}

tr.edit_row td .edit {
  display: none;
}

tr.edit_row.is-visible td {
  pointer-events: none;
}

tr.edit_row.is-visible td.buttons {
  pointer-events: visible;
}

tr.edit_row.is-visible td .save {
  display: none !important;
}

tr.edit_row.is-visible td .edit {
  display: inline-grid !important;
}

tr.edit_row.is-visible .v-input__append-inner {
  display: none !important;
}

tr.edit_row.is-visible label.v-label.v-label--active.theme--light {
  display: none;
}
</style>
