<template>
  <div class="text-right">
    <v-dialog v-model="dialog" persistent height="auto" width="1100">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="tway-violet--text"> mdi-cog-outline </v-icon>
          CONFIGURACIÓN
        </v-btn>
      </template>
      <v-card :class="'modalSystem'">
        <v-card>
          <div class="container">
            <v-btn icon right @click="closeModal()" class="float-right">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3 class="text-center">Configuración de Presupuesto</h3>
            <h4 class="text-center font-weight-regular">Unidades de costo</h4>
            <form>
              <div>
                <v-row class="mt-6">
                  <v-col md="4">
                    <v-text-field
                      v-model="form.title"
                      label="Título Unidad de Costo"
                      type="text"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      outlined
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.monetaryUnit"
                      label="Moneda Presupuesto"
                      type="text"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
              <v-divider class="mt-6"></v-divider>
              <div class="d-flex justify-space-between mt-2">
                <v-row class="mt-6">
                  <v-col md="4">
                    <v-text-field
                      v-model="form.unitOne"
                      label="Nombre unidad 1"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.unitTwo"
                      label="Nombre unidad 2"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.unitThree"
                      label="Nombre unidad 3"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.unitFour"
                      label="Nombre unidad 4"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      type="text"
                      outlined
                    />
                  </v-col>
                  <v-col md="4">
                    <v-text-field
                      v-model="form.unitFive"
                      label="Nombre unidad 5"
                      :hide-details="true"
                      :maxlength="25"
                      :minlenght="0"
                      type="text"
                      outlined
                    />
                  </v-col>
                </v-row>
              </div>
            </form>
            <v-divider class="my-6"> </v-divider>
            <!-- Presupuestos -->
            <h4 class="text-center font-weight-regular mt-6">Ingresa nuevo presupuesto</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th class="text-center" width="15%" scope="col">Año</th>
                    <th class="text-center" width="15%" scope="col">Mes</th>
                    <th class="text-center" width="17%" scope="col">Centro Costo</th>
                    <th class="text-center" width="17%" scope="col">Presupuesto</th>
                    <th class="text-center" width="17%" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr :v-if="item.length > 0" v-for="item in itemBudget" :key="item">
                    <td class="text-center">
                      <v-select
                        :items="combos.years"
                        item-value="id"
                        item-text="name"
                        v-model="item.year"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="combos.months"
                        item-value="id"
                        item-text="name"
                        v-model="item.month"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="combos.costCenters"
                        item-value="id"
                        item-text="name"
                        v-model="item.costCenter"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-text-field v-model="item.amount" maxlength="15" minlenght="0" outlined></v-text-field>
                    </td>
                    <td class="text-center buttons d-flex align-end">
                      <v-btn
                        class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text"
                        outlined
                        color="indigo lighten-2"
                        @click="addBudget(item)"
                      >
                        <div v-if="!loading.buttonSaveTrack">
                          <v-icon class="tway-violet--text"> mdi-plus </v-icon>
                          Agregar
                        </div>
                        <div v-else>Loading....</div>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <h4 class="text-center font-weight-regular mt-6">Listado de Configuración Presupuesto</h4>
            <v-simple-table class="table mt-5" fixed-header>
              <template>
                <thead>
                  <tr>
                    <th width="5%" scope="col">ID</th>
                    <th width="20%" scope="col">Año</th>
                    <th width="20%" scope="col">Mes</th>
                    <th width="20%" scope="col">Centro Costo</th>
                    <th width="20%" scope="col">Presupuesto</th>
                    <th class="text-center" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :v-if="items.length > 0"
                    v-for="item in items"
                    :key="item.index"
                    :class="item.isVisible ? hidden : activeClass"
                    class="edit_row"
                  >
                    <td class="text-center">{{ item.index }}</td>
                    <td class="text-center">
                      <v-select
                        :items="item.years"
                        item-value="id"
                        item-text="name"
                        v-model="item.year"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="item.months"
                        item-value="id"
                        item-text="name"
                        v-model="item.month"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <v-select
                        :items="item.costCenters"
                        item-value="id"
                        item-text="name"
                        v-model="item.costCenter"
                        outlined
                      ></v-select>
                    </td>
                    <td class="text-center">
                      <span class="peso">{{ form.monetaryUnit }}</span>
                      <v-text-field
                        v-model="item.amount"
                        type="number"
                        maxlength="15"
                        minlenght="0"
                        :counter="15"
                        outlined
                      ></v-text-field>
                    </td>
                    <td class="text-center buttons">
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="edit" @click="saveItem(item)" v-bind="attrs" v-on="on">
                            <v-icon>edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" class="save" @click="addBudget(item)" v-bind="attrs" v-on="on">
                            <v-icon>save</v-icon>
                          </v-btn>
                        </template>
                        <span>Guardar</span>
                      </v-tooltip>
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="error" v-bind="attrs" v-on="on" @click="removeItem(item.index)">
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <v-divider></v-divider>
          <div class="d-flex mt-6 justify-space-around">
            <div class="row">
              <div class="col-12 text-center justify-center pa-md-5 mx-lg-auto">
                <v-spacer></v-spacer>
                <v-btn
                  outlined
                  color="indigo lighten-2"
                  class="button-text v-btn v-btn--outlined v-btn--rounded theme--light v-size--default tway-violet--text mx-lg-auto"
                  @click="closeConfigurationDialog()"
                >
                  <v-icon class="tway-violet--text"> save</v-icon>
                  Guardar
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BudgetService from "@/services/pmo/budget/budgetService";

export default {
  data: () => {
    return {
      dialog: false,
      form: {
        title: "",
        monetaryUnit: "",
        unitOne: "",
        unitTwo: "",
        unitThree: "",
        unitFour: "",
        unitFive: "",
      },
      itemBudget: [
        {
          index: "",
          year: "",
          month: "",
          costCenter: "",
          amount: "",
        },
      ],
      combos: {
        years: [],
        months: [],
        costCenters: [],
      },
      maxIndex: 0,
      items: [],
      nameRules: [(v) => !!v || "Requerido.", (v) => v.length <= 25 || "Máximo 25 carácteres."],
      isVisible: true,
      activeClass: "is-visible",
      project: {},
      loading: {
        saveCostCenterUnits: false,
      },
      messageError: {
        saveCostCenterUnits: false,
      },
      alert: {
        saveCostCenterUnits: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.dialog = false;
      this.$emit("reloadBudgetCharts");
    },
    closeConfigurationDialog() {
      this.saveCostCenterUnits();
      this.closeModal();
    },
    saveItem(item) {
      item.isVisible = !item.isVisible;
      this.$forceUpdate();
    },
    AddItem() {
      this.maxIndex++;
      this.items.push({
        index: this.maxIndex,
        years: this.combos.years,
        months: this.combos.months,
        costCenters: this.combos.costCenters,
        year: 2,
        month: 1,
        costCenter: 0,
        amount: 0,
      });
    },
    removeItem(index) {
      const dataToRemove = {
        index: index,
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
      };

      BudgetService.deleteBudgetConfigurationByIndex(dataToRemove)
        .then(() => {
          this.getAllBudgetByIdProjectAndIdEnterprise();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveCostCenterUnits = true;
          this.messageError.saveCostCenterUnits = message;
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveCostCenterUnits = false;
          setTimeout(() => (this.alert.saveCostCenterUnits = false), 4000);
        });
    },
    addBudget(item) {
      item.isVisible = !item.isVisible;

      const data = {
        index: item.index,
        year: item.year,
        month: item.month,
        costCenter: item.costCenter,
        amount: item.amount,
        projectId: this.project.projectId,
        enterpriseId: this.project.enterpriseId,
      };
      console.log("datos enviados: ", data);
      this.items = [];

      BudgetService.addBudget(data)
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveCostCenterUnits = true;
          this.messageError.saveCostCenterUnits = message;
          console.dir(err);
        })
        .finally(() => {
          this.getAllBudgetByIdProjectAndIdEnterprise();
          this.loading.saveCostCenterUnits = false;
          setTimeout(() => (this.alert.saveCostCenterUnits = false), 4000);

          this.$forceUpdate();
          this.$emit("reloadBudgetCharts");
        });
    },
    saveCostCenterUnits() {
      this.form.projectId = this.project.projectId;
      this.form.enterpriseId = this.project.enterpriseId;

      this.loading.saveCostCenterUnits = true;

      BudgetService.addCostCenter(this.form)
        .then(() => {
          this.getAllBudgetByIdProjectAndIdEnterprise();
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveCostCenterUnits = true;
          this.messageError.saveCostCenterUnits = message;
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveCostCenterUnits = false;
          setTimeout(() => (this.alert.saveCostCenterUnits = false), 4000);
          this.$forceUpdate();
          this.$emit("reloadBudgetCharts");
        });
    },
    getAllBudgetByIdProjectAndIdEnterprise() {
      BudgetService.getAllBudgetByIdProjectAndIdEnterprise(this.project)
        .then((data) => {
          this.items = data.items;
          this.combos.years = data.general.years;
          this.combos.months = data.general.months;
          this.combos.costCenters = data.general.costCenters;
          this.maxIndex = data.general.maxIndex;
        })
        .catch((err) => {
          let message = err.response.data.message;
          this.alert.saveCostCenterUnits = true;
          this.messageError.saveCostCenterUnits = message;
          console.dir(err);
        })
        .finally(() => {
          this.loading.saveCostCenterUnits = false;
          setTimeout(() => (this.alert.saveCostCenterUnits = false), 4000);
        });
    },
  },
  mounted() {
    let pmoProjectIds = JSON.parse(sessionStorage.getItem("pmoProjectIds"));
    this.project = pmoProjectIds;
    this.getAllBudgetByIdProjectAndIdEnterprise();

    BudgetService.getTableCostCenter(this.project)
      .then((data) => {
        this.form.title = data.title;
        this.form.monetaryUnit = data.monetaryUnit;
        this.form.unitOne = data.unitOne;
        this.form.unitTwo = data.unitTwo;
        this.form.unitThree = data.unitThree;
        this.form.unitFour = data.unitFour;
        this.form.unitFive = data.unitFive;
        console.log("cc: ", data);
      })
      .catch((err) => {
        let message = err.response.data.message;
        this.alert.saveCostCenterUnits = true;
        this.messageError.saveCostCenterUnits = message;
        console.dir(err);
      })
      .finally(() => {
        this.loading.saveCostCenterUnits = false;
        setTimeout(() => (this.alert.saveCostCenterUnits = false), 4000);
      });
  },
};
</script>

<style>
.shrink {
  width: 150px;
}
.bar {
  width: 350px;
}
.modalSystem {
  border-radius: 0px !important;
}
.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active {
  background-color: #fff;
}
.modalSystem .v-card.v-sheet.theme--light {
  padding: 20px 40px;
}
.modalSystem label.v-label.theme--light {
  font-family: "Josefin Sans", sans-serif !important;
}
.modalSystem fieldset {
  border-color: #4a148c !important;
}
.modalSystem label.v-label--active {
  color: #4a148c !important;
}
.modalSystem button.float-right {
  z-index: 9;
  top: 15px;
  right: 15px;
  position: absolute;
}
</style>
